<template>
  <div class="mediaplan-list item-collection">
      <ul>
          <li class="header">
            <div></div>
            <div :class="$store.state.mediaplanning.sortInfo.property == 'name' ? 'active' : ''">Name <SortActions property="name" label="Name" @sort="onSort" /></div>
            <div :class="$store.state.mediaplanning.sortInfo.property == 'last_modified_timestamp' ? 'active' : ''">Date <SortActions property="last_modified_timestamp" label="Date" @sort="onSort" /></div>
            <div>Forecasts</div>
            <div :class="$store.state.mediaplanning.sortInfo.property == 'total_value' ? 'total-value active' : 'total-value'">Budget <SortActions property="total_value" label="Sum" @sort="onSort" /></div>
            <div></div>
          </li>
          <li v-for="(item, index) in mediaplans" v-bind:key="index" :class="clazz(item)">
              <div class="icon">
                <inline-svg v-if="!item.ai_details && !item.is_default_plan" :src="require('@/assets/svg/icons/mediaplan/icon_mediaplan_small_25x25px.svg')" width="25" height="25"></inline-svg>
                <inline-svg v-if="item.ai_details" :src="require('@/assets/svg/icons/mediaplan/icon_mediaplan_small_aiplus_25x25px.svg')" width="25" height="25"></inline-svg>
                <inline-svg v-if="item.is_default_plan" :src="require('@/assets/svg/icons/mediaplan/icon_mediaplan_small_default_25x25px.svg')" width="25" height="25"></inline-svg>
              </div>
              <div class="name">{{ mediaplanName(item) }}
                <HeadlineBadge v-if="item.ai_details" type="aiplus" />
              </div>
              <div class="date nowrap">{{ dateInfos(item).lastModified }}
                <InfoHud v-if="dateInfos(item).lastModified">
                  <slot>
                    <DateInfo :item="item" />
                  </slot>
                </InfoHud>
              </div>
              <div class="nowrap">{{ numberOfPredictionsTitles[item.id] }}</div>
              <div class="total-value nowrap"  v-html="totalValueText(item)"></div>
              <div class="actions nowrap">
                <div class="button-progress-wrapper">
                  <button v-if="showPredictionButton(item)" class="button rounded ghost" @click.stop="onPredictionClick(item)">Create forecast</button>
                  <ProgressBar v-if="showProgressBar(item)" :completed="item.ai_details.percent_done" />
                </div>
                <div class="context-menu-dots icon-button flex"
                     @click.stop="$store.dispatch('ui/toggleContextMenu', { event: $event, data: item, menuItems: contextMenuItems[item.id] })">
                    <inline-svg
                        :src="require('@/assets/svg/icons/main_window/icon_options_default_24x5px.svg')" width="24" height="5">
                    </inline-svg>
                </div>
              </div>
          </li>
      </ul>
      <div v-if="!mediaplans || mediaplans.length == 0">No media plans available</div>
      <ConfirmDialog v-if="mediaplanToBeDeleted" title="Delete media plan?">
        <template v-slot:message><span v-html="deleteConfirmMessage"></span></template>
        <template v-slot:actions>
          <div class="button-group">
            <button @click.stop="doCancelDelete" class="button ghost mediumsize rounded">Cancel</button>
            <button @click.stop="doDeleteMediaplan" class="button primary mediumsize rounded">Delete</button>
          </div>
        </template>
      </ConfirmDialog>
      <RenameDialog
        v-if="itemToBeRenamed"
        title="Rename media plan"
        :oldName="itemToBeRenamed.name"
        v-model="newItemName"
        :error="renamingError"
        @rename:cancel="doCancelRename"
        @rename:save="doRenameMediaplan"
      />
  </div>
</template>

<script>

import UiMixin from "@/components/mixins/UiMixin.vue"
import RenameMixin from "@/components/mixins/RenameMixin.vue"
import Util from "@/util.js"
import MediaplanListMixin from '@/components/mixins/MediaplanListMixin.vue'
import ProgressBar from "@/components/ui/ProgressBar"
import HeadlineBadge from "@/components/ui/HeadlineBadge.vue"
import InfoHud from "@/components/ui/InfoHud.vue"
import DateInfo from "@/components/lists/DateInfo.vue"
import useUI from '@/js/useUI.js'
import SortActions from "@/components/lists/SortActions.vue"
import ConfirmDialog from "@/components/ui/ConfirmDialog.vue"
import RenameDialog from "../ui/RenameDialog.vue"

export default {
  name: "MediaplanList",
  mixins: [UiMixin, MediaplanListMixin, RenameMixin],
  components: {
    ConfirmDialog,
    ProgressBar,
    HeadlineBadge,
    InfoHud,
    DateInfo,
    SortActions,
    RenameDialog
},
  data: function() {
    return {
      mediaplanToBeDeleted: null,
    }
  },
  methods: {
    onSort(sortInfo) {
      this.$store.commit("mediaplanning/sortInfo", sortInfo)
    },
    onPredictionClick(mediaplan) {
      this.$store.commit("mediaplanning/currentMediaplanId", mediaplan.id);
      this.$router.push("/predictions/scenario");
      let ui = useUI(this.$store)
      ui.blockUI({ unblockDisabled: true });
    },
    clazz(mediaplan) {
      if (mediaplan.is_default_plan) {
        return 'list-badge list-badge-default'
      }
      return ''
    },
    doRenameMediaplan() {
      useUI(this.$store).unblockUI()
      this.renamingError = null
      this.$store
        .dispatch("mediaplanning/updatePlan", {
            id: this.itemToBeRenamed.id,
            name: this.newItemName
        })
        .then(() => {
          this.itemToBeRenamed = null
          this.newItemName = null
          this.$store.dispatch("mediaplanning/fetchAllPlans").then( () => {
            this.$store.dispatch("predictions/fetchAllPredictions").then( () => {
              useUI(this.$store).unblockUI()
            })
          });
        })
        .catch( (error, response) => {
          console.log("renamingError", error, response)
          this.renamingError = error
        });
    },
    doDeleteMediaplan() {
      this.$store
        .dispatch("mediaplanning/deletePlan", this.mediaplanToBeDeleted.id)
        .then(() => {
          this.mediaplanToBeDeleted = null;
          this.$store.dispatch("mediaplanning/fetchAllPlans").then( () => {
            this.$store.dispatch("predictions/fetchAllPredictions").then( () => {
              useUI(this.$store).unblockUI()
            })
          });
        });
    },
    doCancelDelete() {
      useUI(this.$store).unblockUI()
      this.mediaplanToBeDeleted = null
    },
  },
  computed: {
    deleteConfirmMessage() {
      let numPredictions = this.mediaplanToBeDeleted.n_predictions
      let s = `Do you really whish to delete <b>${this.mediaplanToBeDeleted.name}</b>?`
      if (numPredictions == 1) {
        s += "This will also delete 1 linked forecast."
      } else if (numPredictions > 1) {
        s += `This will also delete ${numPredictions} linked forecasts.`
      }
      return s
    },
    mediaplans() {
      return this.$store.getters["mediaplanning/mediaplans"];
    },
    contextMenuItems() {
      return Util.contextMenuItems(this.mediaplans, (mediaplan) => {
        let items = [];
        if (this.isMediaplanDownloadEnabled(mediaplan)) {
          items.push({
            title: "Download (national)",
            handler: () => {
              this.download("mediaplanning/downloadPlanNational", mediaplan.id)
            },
          })
          items.push({
            title: "Download (regional)",
            handler: () => {
              this.download("mediaplanning/downloadPlan", mediaplan.id)
            },
          })
        }
        if (!mediaplan.readonly) {
          items.push({
            title: "Rename",
            handler: () => {
              useUI(this.$store).blockUI({ complete: true, unblockDisabled: true })
              this.itemToBeRenamed = mediaplan
              this.newItemName = this.itemToBeRenamed.name
            }
          })
          items.push({
            title: "Delete",
            handler: () => {
              useUI(this.$store).blockUI({ complete: true })
              this.mediaplanToBeDeleted = mediaplan
            }
          })
        }
        return items
      });
    },
  },
};
</script>

<style scoped>
.total-value {
  color: var(--c-button-blue);
  text-align: right;
  padding: 20px;
}
:deep(.info-hud) {
  margin-left: 8px;
}
</style>
