<template>
  <div :class="'main-header ' + ($store.state.ui.isMasterViewBlocked ? 'dimmer' : '')">
    <div class="left">
      <img class="button" src="@/assets/svg/burger.svg" @click="onBurgerClick">
    </div>
    <div class="center">
      <MainNav />
    </div>
    <div class="right">
      <SegmentSelector />
    </div>
  </div>
</template>

<script>
import MainNav from "./MainNav.vue"
//import BrandSelector from "./BrandSelector.vue"
import SegmentSelector from "@/components/segments/SegmentSelector.vue"

export default {
  name: 'MainHeader',
  components: {
    MainNav,
    //BrandSelector,
    SegmentSelector
  },
  props: {
  },
  methods: {
    onBurgerClick() {
      this.$store.commit("ui/isSiteMenuShown", true)
    }
  },
  computed: {
  }
}
</script>

<style scoped>
.button {
  cursor: pointer;
}
.main-header {
  padding: 40px 80px 40px 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  position: relative;

}
nav {
  display: flex;
  flex-direction: column;
}
.nav-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.nav-inner {
  background-color: aqua;
}
.item {
  text-transform: uppercase;
  cursor: pointer;
  color: var(--c-greyish);
  letter-spacing: 2px;
  font-size: 14px;
  width: 120px;
}
.item.active {
  color: #000;
}
.active-indicator {
  background-color: var(--c-button-blue);
  height: 2px;
  width: 42px;
}
.left {
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
}
.right {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 10px;
}
.center {
  display: flex;
  align-items: center;
}
</style>
